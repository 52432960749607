import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <header
          style={{
            display: `flex`,
            alignItems: `center`,
            marginBottom: "2.295rem",
          }}
        >
          <h1
            style={{
              ...scale(1.5),
              marginBottom: "0",
              borderRight: "1px solid #333",
              flex: `1`,
              paddingRight: "1rem",
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
          <div style={{ flex: "4", paddingLeft: "1rem" }}>
            A personal blog by Anja Gusev
          </div>
        </header>
      )
    } else {
      header = (
        <header>
          <h3
            style={{
              marginTop: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
        </header>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        <main>{children}</main>
      </div>
    )
  }
}

export default Layout
